<template>
  <div>
    <b-alert :show="working" variant="info" class="d-flex align-items-center px-5">
      <strong>Working...</strong>
      <b-spinner class="ml-auto"></b-spinner>
    </b-alert>
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" v-if="show" class="animated fadeIn" :hidden="working">

      <!-- Member -->
      <b-form-group
        label="Member"
        label-for="registration-select-user"
        label-cols-sm="4"
      >
        <FormSelectUser id="registration-select-user" 
          placeholder="Select a Member" 
          :selected="registrationForm.user" 
          :reduce="opt => opt['@id']"
          :newUser="false" 
          :disabled="!!registrationForm.id" 
          @input="setUser" 
        />
      </b-form-group>
      <b-form-group
        label="As Coach"
        label-for="registration-select-coach"
        label-cols-sm="4"
      >
        <b-form-select id="registration-select-coach" :options="[
          { value: 'head', text: 'Yes - Head coach' },
          { value: 'assistant', text: 'Yes - Assistant coach' },
          { value: 'open', text: 'Yes - Head or assistant coach' },
          { value: '-', text: 'No - Not interested right now' },
          { value: null, text: 'Please select an option', disabled: true },
        ]" v-model="registrationForm.coachVolunteer"></b-form-select>
      </b-form-group>
      <hr />

      <!-- Participant -->
      <b-form-group
        label="Participant"
        label-for="registration-select-participant"
        label-cols-sm="4"
      >
        <FormSelectParticipant 
          id="registration-select-participant" 
          :member="registrationForm.user" 
          :selected="registrationForm.participant" 
          :reduce="opt => opt['@id']"
          @input="setParticipant" 
          memberRequired 
        />
      </b-form-group>

      <!-- Event -->
      <b-form-group
        label="Event"
        label-for="registration-select-event"
        label-cols-sm="4"
      >
        <b-form-select id="registration-select-event" v-model="registrationForm.event" :options="events" value-field="@id" text-field="title">
          <template v-slot:first>
            <option :value="null" disabled>-- Please select an event --</option>
          </template>
        </b-form-select>
      </b-form-group>

      <!-- Event Questions -->
      <b-form-group
        v-for="(question, index) in eventQuestions"
        :label="question.label"
        label-for="registration-select-event"
        label-cols-sm="4"
        :key="index"
      >
        <b-form-select v-if="question.options.length" :options="question.options" text-field="label" value-field="id" v-model="registrationForm.questionResponses[question.id]">
          <template v-slot:first>
            <option :value="null" disabled>-- Please select a response --</option>
          </template>
        </b-form-select>
        <b-form-input v-else v-model="registrationForm.questionResponses[question.id]"></b-form-input>
      </b-form-group>

      <!-- Request -->
      <b-form-group
        label="Registrant Request"
        label-for="registration-request"
        label-cols-sm="4"
      >
        <b-form-textarea id="registration-request" v-model="registrationForm.request" placeholder="Requests from the registrant (optional)"></b-form-textarea>
      </b-form-group>

      <!-- Notes -->
      <b-form-group
        label="Admin Notes"
        label-for="registration-notes"
        label-cols-sm="4"
      >
        <b-form-textarea id="registration-notes" v-model="registrationForm.notes" placeholder="Admin notes regarding this registration (optional)"></b-form-textarea>
      </b-form-group>

      <template v-if="!registrationPaid">
        <!-- Price -->
        <b-form-group
          label="Registration Price"
          label-for="registration-price"
          label-cols-sm="4"
        >
          <b-input-group prepend="$">
            <b-form-input id="registration-price" v-model.number="invoiceItemForm.price" placeholder="Price for this registration" required></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- Invoice -->
        <b-form-group
          label="Invoice"
          label-for="registration-select-invoice"
          label-cols-sm="4"
        >
          <b-form-select
            id="registration-select-invoice"
            v-model="registrationForm.invoice"
            :options="invoiceOptions"
          >
            <template v-slot:first>
              <option :value="null">New Invoice</option>
            </template>
          </b-form-select>
        </b-form-group>
      </template>
      
      <b-form-group
        v-else
        label="Invoice"
        label-for="registration-select-invoice"
        label-cols-sm="4"
      >
        <p>This event has already been marked as paid. </p>
      </b-form-group>

      <hr />
      <div class="offset-sm-4">
        <b-button type="reset" variant="danger">Reset</b-button>
        <b-button type="submit" class="float-right" variant="success">{{ registrationForm.id ? "Update Registration" :"Submit Registration" }}</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormSelectUser from '@/components/FormSelectUser'
import FormSelectParticipant from '@/components/FormSelectParticipant'
// import { constants } from '@/shared/constants'

const EVNT_REG_TAXABLE = true

export default {
  components: { FormSelectUser, FormSelectParticipant },
  data () {
    return {
      show: true,
      working: false,
      newRegistration: true,
      uid: null,
      originalRegistration: null,
      registrationForm: {
        '@id': null,
        id: null,
        user: null,
        participant: null,
        request: null,
        notes: null,
        questionResponses: {},
        event: null,
        coachVolunteer: null,
        invoice: null
      },
      invoiceId: null,
      iniInvoiceId: null,
      invoiceItemForm: {
        id: null,
        price: null
      },
      registrationPaid: false,
      eventQuestions: [],
      newMember: false,
      newMemberVal: 'new',
      newParticipant: false,
      newParticipantVal: 'new'
    }
  },
  computed: {
    ...mapGetters([
      'invoices',
      'events'
    ]),
    invoiceOptions () {
      return this.invoices.map(e => ({
        value: e['@id'],
        text: 'Invoice #' + e.id
      }))
    }
  },
  watch: {
    'registrationForm.event' () {
      this.setEventPriceDefault()
    },
    'registrationForm.user' (val) {
      if (val) {
        this.getMemberInvoices()
      } else {
        this.resetInvoices()
      }
    },
    'registrationForm.invoice' (val, prev) {
      if (!val) {
        this.invoiceId = null
      } else {
        const rid = this.registrationForm.invoice.split('/')
        this.invoiceId = parseInt(rid[rid.length - 1])
      }
    }
  },
  async mounted () {
    // this.working = true
    await this.getEvents({
      business: this.currentBusiness.id,
      status: [1,2]
    })
    if (this.$route.params.rid) {
      let regDetails = {}
      try {
        // get registration by ID
        regDetails = await this.getEventRegistration(this.$route.params.rid)
        // get registration event by ID
        let eventDetails = await this.getEvent({ id: regDetails.event.id }),
        eventQuestions = []
        // set original data for later comparison
        this.originalRegistration = regDetails
        // set selectable event questions asked during registration
        for (let i in eventDetails.questions) {
          eventQuestions.push(eventDetails.questions[i].id)
        }
        // set registration data
        let registration = {
          '@id': regDetails['@id'],
          id: regDetails.id,
          business: regDetails.business,
          user: regDetails.user['@id'],
          participant: regDetails.participant['@id'],
          notes: regDetails.notes,
          request: regDetails.request,
          questionResponses: regDetails.questionResponses,
          event: regDetails.event['@id'],
          coachVolunteer: regDetails.coachVolunteer
        }

        if (regDetails.invoice) {
          registration.invoice = regDetails.invoice['@id']
          this.registrationPaid = (!!regDetails.invoice.transactionId)
        } else {
          registration.invoice = null
        }

        this.registrationForm = registration
        // get selectable event questions 
        await this.getEventQuestions({
          id: eventQuestions
        }).then(resp => {
          this.eventQuestions = resp
        })
        this.newRegistration = false
      } catch (err) {
        console.error('there was an error loading the registration')
      }

      if (this.registrationForm.invoice) {
        try  {
          // set registration invoice ID
          this.invoiceId = regDetails.invoice.id
          this.iniInvoiceId = this.invoiceId
          // set registration invoice item data
          let invoiceItemForm = {
            id: regDetails.invoiceItem.id,
            price: regDetails.invoiceItem.price
          }
          this.invoiceItemForm = invoiceItemForm
        } catch (err) {
          console.error('there was an error loading the invoice')
        }
      }
      
    } 
    // else {
    //   this.$router.go(-1)
    //   this.showMessage({ type: 'info', title: 'Sorry, not yet', message: 'The new registration feature is still being developed.', timeout: 5000 })
    //   return
    // }
    try  {
      if (this.$route.query.mid) {
        this.registrationForm.user = '/api/users/' + this.$route.query.mid
      }
      if (this.$route.query.pid) {
        this.registrationForm.participant = '/api/participants/' + this.$route.query.pid
      }
      if (this.$route.query.eid) {
        let eventQuestions = []
        let eventDetails = await this.getEvent({ id: this.$route.query.eid })
        for (let i in eventDetails.questions) {
          eventQuestions.push(eventDetails.questions[i].id)
        }
        // get selectable event questions 
        await this.getEventQuestions({
          id: eventQuestions
        }).then(resp => {
          this.eventQuestions = resp
        })
        this.eventQuestions.map(q => {
          this.registrationForm.questionResponses[q.id] = null
        })
        this.registrationForm.event = '/api/events/' + this.$route.query.eid
      }
    } catch (err) {
      console.error('there was an error loading the default values')
    }
    // this.working = false
  },
  methods: {
    ...mapActions([
      'getInvoices',
      'resetInvoices',
      'getEventRegistration',
      'postEventRegistration',
      'putEventRegistration',
      'getEventQuestions',
      'getEvents',
      'getEvent',
      'getParticipant',
      'postInvoice',
      'putInvoice',
      'postInvoiceItem',
      'putInvoiceItem',
      'getInvoiceItems'
    ]),
    setUser (val) {
      // this.registrationForm.participant = null
      if (!val) { 
        this.registrationForm.user = null
        this.registrationForm.participant = null
        return
      }
      if (val === this.newMemberVal) {
        this.registrationForm.user = null
        this.newMember = true
        return
      }
      this.registrationForm.user = val
    },
    setParticipant (val) {
      if (!val) { 
        this.registrationForm.participant = null
        return 
      }
      this.registrationForm.participant = val
    },
    async onSubmit() {
      // this.working = true
      try {
        if (!this.registrationPaid) {
          await this.saveInvoice()
        }
        await this.saveRegistration()
        if (!this.registrationPaid) {
          await this.saveInvoiceItem()
          await this.recalculateInvoice(this.invoiceId)
        }
      } catch (err) {
        console.error('there was an error saving the registration')
      }
      this.showSuccess({message: 'The registration has been saved.'})
      if (!this.newRegistration) {
        this.$router.push({ name: 'eventRegistrationDetails', params: { rid: this.registrationForm.id } })
      } else {
        this.$router.push({ name: 'invoiceDetails', params: { iid: this.invoiceId } })
      }
      
      // this.working = false
    },
    // @TODO: move to a separate common library
    async saveInvoice () {
      let invoice
      if (!this.invoiceId) {
        let invoiceData = {
          business: this.currentBusiness['@id'],
          member: this.registrationForm.user,
          amount: 0,
          amountDue: 0
        }
        invoice = await this.postInvoice(invoiceData)
        this.invoiceId = invoice.id
        this.registrationForm.invoice = invoice['@id']
      }
      return invoice
    },
    // @TODO: move to a separate common library
    async saveInvoiceItem () {
      let participantIri = this.registrationForm.participant.split('/')
      let participant = await this.getParticipant({
        id: participantIri[participantIri.length - 1]
      })
      let event = this.events.find(event => event['@id'] === this.registrationForm.event)
      if (!this.invoiceItemForm.id) {
        // @TODO
        let invoiceItem = {
          registration: '/api/event_registrations/' + this.registrationForm.id,
          invoice: this.registrationForm.invoice,
          pid: 'EVNTREG-' + this.registrationForm.id,
          item: `${participant.fullName}: ${event.title}`,
          price: parseFloat(this.invoiceItemForm.price),
          quantity: 1,
          taxable: EVNT_REG_TAXABLE
        }
        let item = await this.postInvoiceItem(invoiceItem).then(resp => {
          this.invoiceItemForm.id = resp.id
        })

        if (event.otherFees && Object.keys(event.otherFees).length) {
          for (let key in event.otherFees) {
            let fee = event.otherFees[key]
            let feeItem = {
              // registration: '/api/event_registrations/' + this.registrationForm.id,
              invoice: this.registrationForm.invoice,
              pid: 'EVNTREG-' + this.registrationForm.id + '-FEE-' + key,
              item: `${participant.fullName}: ${event.title} (${key})`,
              price: parseFloat(fee.amount),
              quantity: 1,
              taxable: fee.taxable
            }
            await this.postInvoiceItem(feeItem)
          }
        }

        return item;
      }
      this.invoiceItemForm.invoice = this.registrationForm.invoice
      this.invoiceItemForm.item = `${participant.fullName}: ${event.title}`
      return await this.putInvoiceItem(this.invoiceItemForm)
    },
    // @TODO: move to a separate common library
    async saveRegistration () {
      if (!this.registrationForm.id) {
        this.registrationForm.business = this.currentBusiness['@id']
         let registration = await this.postEventRegistration(this.registrationForm)
         this.registrationForm.id = registration.id
         return registration
      }
      return await this.putEventRegistration(this.registrationForm)
    },
    onReset() {
      /* Reset our form values */
      this.registrationForm.user = ''
      this.registrationForm.participant = ''
      /* Trick to reset/clear native browser form validation state */
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    setEventPriceDefault () {
      let event = this.events.find(event => event['@id'] === this.registrationForm.event)
      if (!event || !event.pricing.length) return
      let price = 0,
      now = this.$moment().format(), 
      currentTierStarts = null
      for (let i in event.pricing) {
        let tierStarts = this.$moment(event.pricing[i].datetimeStart).format()
        if (tierStarts <= now) {
          if (currentTierStarts === null) {
            price = event.pricing[i].price
            currentTierStarts = tierStarts
          } else if (tierStarts >= currentTierStarts) {
            price = event.pricing[i].price
            currentTierStarts = tierStarts
          }
        }
      }
      this.invoiceItemForm.price = parseFloat(price)
    },
    getMemberInvoices () {
      const mid = this.registrationForm.user.split('/')
      if (mid) {
        this.getInvoices({
          member: mid[mid.length - 1],
          'exists[transactionId]': false
        })
      }
    }
  },
}
</script>