<template>
  <vSelect 
    v-model="selectedObject" 
    label="fullName"
    :placeholder="placeholder" 
    :options="options"
    :reduce="reduce"
    :disabled="disabled"
    :readonly="readonly"
    :required="required" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  props: {
    member: {
      default () { return null }
    },
    memberRequired: {
      type: Boolean,
      default () { return false }
    },
    placeholder: {
      type: String,
      default: () => 'Select a User'
    },
    required: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    selected: {
      default: () => null
    },
    roles: {
      default: () => 'member'
    },
    newParticipant: {
      default: () => false
    },
    reduce: null
  },
  data () {
    return {
      loaded: false,
      selectedObject: null
    }
  },
  computed: {
    ...mapGetters([
      'participants'
    ]),
    options () {
      let options = [ ...this.participants ]
      if (this.newParticipant) {
        let newParticipant = this.newParticipant
        if (typeof newParticipant !== 'object') {
          newParticipant = { id: newParticipant, '@id': newParticipant, fullName: 'New User' }
        }
        options.push(newParticipant)
      }
      return options
    }
  },
  methods: {
    ...mapActions([
      'getParticipants',
      'resetParticipants'
    ]),
    doGetParticipants () {
      this.resetParticipants()
      if (this.memberRequired && !this.member) {
        return
      }
      let params = {}
      if (this.member) {
        params['relationships.user'] = this.member
      } else {
        params['businesses'] = this.currentBusiness.id
      }
      this.getParticipants(params)
    },
    setPlaceholderVal () {
      if (this.memberRequired && !this.member) {
        this.placeholderVal = "No Member Selected"
      } else {
        this.placeholderVal = this.placeholder
      }
    },
    setSelectedObject (val) {
      if (this.loaded && this.selectedObject !== val) {
        this.selectedObject = val
      }
    }
  },
  watch: {
    selected (val) {
      this.setSelectedObject(val)
    },
    selectedObject (val) {
      this.$emit('input', val)
    },
    member (val) {
      this.setPlaceholderVal()
      this.doGetParticipants()
    }
  },
  async mounted () {
    this.setPlaceholderVal()
    await this.doGetParticipants()
    this.loaded = true
  },
  destroyed () {
    this.resetParticipants()
  },
  components: { vSelect }
}
</script>